/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import { Profile, Calendar, Location, Clock } from '@one-thd/sui-icons';
import {
  Typography,
  DrawerBody,
  DrawerHeader,
  Drawer,
  SkeletonLine,
} from '@one-thd/sui-atomic-components';
import { object, func, bool } from 'prop-types';

function SuccessContent({ registrationData, isOpen, onClose }) {
  const formatDate = (dateStr) => {
    const date = new Date(Date.parse(dateStr));
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  };

  if (!registrationData) {
    return (
      <div className="sui-flex sui-flex-col sui-gap-6" data-testid="skeleton-lines">
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
      </div>
    );
  }

  return (
    <Drawer open={isOpen} onClose={onClose} position="right">
      <DrawerHeader title="You&apos;re Signed Up!" onClose={onClose} data-testid="drawer-header" />
      <DrawerBody>
        <div className="sui-mt-2 sui-grid sui-grid-cols-1 sui-gap-6">
          <Typography variant="body-lg" weight="regular" height="tight">
            <div className="sui-flex sui-items-center sui-gap-4" data-testid="profile-icon">
              <Profile color="black" />
              <span data-testid="num-participants">{registrationData?.numOfParticipants} Kid Doers</span>
            </div>
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight">
            <div className="sui-flex sui-items-center sui-gap-4">
              <Calendar color="black" data-testid="calendar-icon" />
              <span>{formatDate(registrationData?.date)}</span>
            </div>
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight">
            <div className="sui-flex sui-items-center sui-gap-4">
              <Location color="black" data-testid="location-icon" />
              <div>
                {registrationData?.storeName} #{registrationData?.storeId}
                <br />
                <span data-testid="address-street">{registrationData?.address?.street}</span>
                <br />
                <span data-testid="address-city-state">
                  {registrationData?.address?.city}, {registrationData?.address?.state}, {registrationData?.address?.postalCode}
                </span>
              </div>
            </div>
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight">
            <div className="sui-flex sui-items-center sui-gap-4" data-testid="clock-icon">
              <Clock color="black" />
              <span data-testid="time-range">Any time from 9 am - 12 pm</span>
            </div>
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight" data-testid="confirmation-message">
            You should receive an email confirmation soon with more details.
            <br />
          </Typography>
        </div>
      </DrawerBody>
    </Drawer>

  );
}

SuccessContent.propTypes = {
  registrationData: object.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default SuccessContent;
