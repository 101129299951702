import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@one-thd/sui-atomic-components';

import { getTarget } from '../utils/getTarget';

/**
 * The `HeroButton` component comes with two variants, "primary" (b2c orange) and "dark".
 *
 * The Dark variant is the new bespoke button variant for the Event Hero.
 * - This will be triggered in the future by data from contentful, currently under construction in a separate story.
 * TODO - Once the Contentful data work is completed we'll need to remove the logic inside `Hero.js` && `StoryHero.js`.
 */

const HeroButton = forwardRef(({
  ariaLabel,
  children,
  componentId,
  href,
  variant = 'defaultB2c',
  onClick = () => { return null; },
  shouldOpenKidsWorkshop,
  title = '',
}, ref) => {

  let colortheme;

  switch (variant) {
  case 'eventB2c':
    colortheme = 'sui-text-inverse sui-bg-inverse hover:sui-bg-button-secondary hover:sui-text-primary';
    break;
  case 'defaultB2b':
    colortheme = 'sui-text-inverse sui-bg-button-primary hover:sui-bg-button-secondary hover:sui-text-primary';
    break;
  default:
    // default case is for defaultB2c && eventB2b
    colortheme = 'sui-text-inverse sui-bg-button-primary hover:sui-bg-inverse hover:sui-text-inverse';
  }

  const darkClasses = useMemo(() => {
    return classNames(
      'sui-relative sui-inline-flex sui-items-center sui-justify-center sui-align-middle',
      'sui-box-border sui-rounded-base sui-border-0 sui-outline-none sui-outline-0',
      'sui-cursor-pointer sui-tap-highlight-transparent sui-select-none sui-appearance-none sui-no-underline',
      'sui-px-4 sui-h-11 sui-w-fit',
      'focus-visible:sui-bg-button-focus focus-visible:sui-text-primary',
      'sui-font-bold sui-text-base sui-leading-normal sui-tracking-normal sui-normal-case sui-line-clamp-unset',
      'hover:sui-no-underline',
      colortheme
    );
  }, [variant]);

  const handleCtaClick = (event) => {
    event.preventDefault();
    onClick(true);
  };

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <>
      {!shouldOpenKidsWorkshop && (
        <a
          className={darkClasses}
          href={href}
          ref={ref}
          target={getTarget(href)}
          data-contentful-field-id="cta"
          data-contentful-entry-id={componentId}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      )}
      {shouldOpenKidsWorkshop && (
        <Button
          data-contentful-entry-id={componentId}
          data-contentful-field-id="cta"
          data-testid="override-action-cta"
          variant="primary"
          onClick={handleCtaClick}
          aria-label={`Sign-up for ${title} workshops`}
        >
          {children}
        </Button>
      )}
    </>
  );
});

HeroButton.displayName = 'HeroButton';

HeroButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  // Disabling this because the ESLint defaultArguments rule doesn't work with forwardRef well.
  // Tested the default Argument works properly, codewise.
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.oneOf(['eventB2c', 'eventB2b', 'defaultB2b', 'defaultB2c']),
  componentId: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  shouldOpenKidsWorkshop: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string
};

export { HeroButton };
