/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense, useState } from 'react';
import {
  arrayOf,
  bool,
  number,
  shape,
  string
} from 'prop-types';
import classNames from 'classnames/bind';
import { useStore } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  Button,
  LoadingButton,
  Typography,
  Card,
  CardBody,
  CardMedia,
  CardTitle,
  CardActions,
  CardContent,
} from '@one-thd/sui-atomic-components';
import { KidsWorkshopsRegistration } from '@thd-olt-component-react/kids-workshops-registration';
import { RichText } from '@thd-olt-component-react/rich-text';
import { SpotlightB2b } from './SpotlightB2b';
import { HrefLink, VideoPlayerWrap } from './subcomponents';
import { akamaiImageResize } from './utils/akamaiImageOptimizer';
import { appendUrlParam } from './utils/appendUrlParam';
import { getTarget } from './utils/getTarget';
import { publish } from './utils/publisher';
import styles from './spotlight.scss';

const SpotlightRenderer = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  data = {},
  lazyLoad = false,
  listOfLinks = [],
  orientation = 'vertical',
  proAnalyticsData = {},
  richText = '',
  showDescription = false,
  useB2bStyles = false,
  isLivePreview = false,
  cmMmcParam = '',
}) => {

  const cx = classNames.bind(styles);

  const {
    cta,
    description,
    link,
    title,
    altText,
    overrideAction,
    proAnalyticsCampaign,
    proAnalyticsComponent,
    videoUrl,
    previewImage: dataComponentPreviewImage
  } = data || {};

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: 'Spotlight',
      component: 'Spotlight',
      position: componentPosition,
      type: 'content',
    },
  });

  // The following logic is for Kids Workshop Registration
  const { storeId } = useStore();
  const [isKidsWorkshopOpen, setIsKidsWorkshopOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const shouldOpenKidsWorkshop = overrideAction === 'Open Workshops Registration Drawer';
  // Can add more `overrideAction` values here

  const toggleKidsWorkshopDrawer = (open) => () => {
    setButtonLoading(false);
    setIsKidsWorkshopOpen(open);
  };

  // # Note: Do we need to be calling `handleContentClick()` inside here?
  const openKidsWorkshopDrawer = () => {
    setIsKidsWorkshopOpen(true);
    setButtonLoading(true);
  };

  const shouldRenderListOfLinks = listOfLinks?.length > 0 && listOfLinks[0].label;
  const damDownloadedContentImage = dataComponentPreviewImage?.damDownloadedContent;

  const imageUrl = akamaiImageResize(
    damDownloadedContentImage?.url
    || dataComponentPreviewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl
  );

  const hasMedia = !!(videoUrl || imageUrl);

  const handleContentClick = () => {
    if (proAnalyticsData.isB2B) {
      // Input Data for widget should be collected from component data
      publish({
        widgetId: componentId,
        widgetTemplates: proAnalyticsData.templates,
        widgetCampaign: proAnalyticsCampaign,
        widgetComponent: proAnalyticsComponent,
      });
    }
  };
  if (useB2bStyles) {
    return (
      <SpotlightB2b
        componentId={componentId}
        componentClass={componentClass}
        componentPosition={componentPosition}
        proAnalyticsData={proAnalyticsData}
      />
    );
  }

  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component="Spotlight"
      data-component-name="Spotlight"
      data-component-position={componentPosition}
      className="sui-grid sui-h-full sui-w-full"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      <Card
        orientation={orientation}
        className={cx('spotlight-card__link--hover-no-underline sui-grid')}
      >
        {hasMedia && (
          <CardContent
            disablePadding
            initialSize={orientation === 'horizontal' ? '2/4' : null}
            data-contentful-entry-id={componentId}
            data-contentful-field-id="videoUrl"
          >
            {videoUrl ? (
              <VideoPlayerWrap
                videoUrl={videoUrl}
              />
            ) : (
              <CardMedia
                src={imageUrl}
                aspect="wide"
                alt={altText || title}
                title={altText || title}
                loading={lazyLoad ? 'lazy' : ''}
                data-contentful-entry-id={componentId}
                data-contentful-field-id="previewImage"
              />
            )}
          </CardContent>
        )}

        <CardContent
          disablePadding
          grow
          initialSize={(orientation === 'horizontal' && hasMedia) ? '2/4' : null}
        >
          <CardTitle
            headerWeight="bold"
            header={title}
            data-contentful-entry-id={componentId}
            data-contentful-field-id="title"
          />
          <CardBody>
            {richText ? (
              <div
                data-contentful-entry-id={componentId}
                data-contentful-field-id="richTextContent"
              >
                <RichText content={richText} isLivePreview={isLivePreview} />
              </div>
            ) : showDescription && description && (
              <Typography
                height="normal"
                weight="regular"
                variant="body-base"
                color="primary"
                data-contentful-entry-id={componentId}
                data-contentful-field-id="description"
              >
                {description}
              </Typography>
            )}
          </CardBody>
          <CardActions className="sui-flex-col">
            {shouldRenderListOfLinks && (
              <div
                className="sui-flex sui-gap-2 sui-flex-col sui-mb-6 sui-mt-4 sui-w-full"
                data-contentful-entry-id={componentId}
                data-contentful-field-id="linkList"
              >
                {listOfLinks?.map((linkItem, key) => {
                  return <HrefLink linkItem={linkItem} key={key} />;
                }
                )}
              </div>
            )}

            {!shouldOpenKidsWorkshop && link && cta && (
              <Button
                data-contentful-entry-id={componentId}
                data-contentful-field-id="cta"
                href={appendUrlParam(link, cmMmcParam)}
                target={getTarget(link)}
                variant="secondary"
                onClick={handleContentClick}
                fullWidth={orientation === 'vertical' || !hasMedia}
                aria-label={`Navigate to ${title}`}
              >
                {cta}
              </Button>
            )}

            {shouldOpenKidsWorkshop && (
              !storeId ? (
                <span>To register for a Kids Workshop, please select your store</span>
              ) : (
                <LoadingButton
                  data-contentful-entry-id={componentId}
                  data-contentful-field-id="cta"
                  variant="secondary"
                  loading={buttonLoading}
                  onClick={openKidsWorkshopDrawer}
                  fullWidth={orientation === 'vertical' || !hasMedia}
                  aria-label={`Sign-up for ${title} workshop`}
                >
                  {cta}
                </LoadingButton>
              )
            )}
          </CardActions>
        </CardContent>
      </Card>
      {shouldOpenKidsWorkshop && storeId && (
        <Suspense fallback={<div>Loading...</div>}>
          <KidsWorkshopsRegistration
            isDrawerOpen={isKidsWorkshopOpen}
            toggleDrawer={toggleKidsWorkshopDrawer}
            oldStoreId={storeId}
          />
        </Suspense>
      )}
    </div>
  );
};

SpotlightRenderer.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  data: shape({}),
  lazyLoad: bool,
  listOfLinks: arrayOf(shape({})),
  orientation: string,
  proAnalyticsData: shape({
    isB2B: bool,
    templates: string,
  }),
  richText: string,
  showDescription: bool,
  useB2bStyles: bool,
  isLivePreview: bool,
  cmMmcParam: string,
};

export { SpotlightRenderer };
