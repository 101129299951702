/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { object, func, bool, string as str } from 'prop-types';
import {
  Form,
  FormButton,
  FormDropdown,
  FormEmail,
  FormTextField,
} from '@thd-olt-component-react/form';
import { Typography, QuantityPicker, Button } from '@one-thd/sui-atomic-components';
import moment from 'moment/moment';
import { readUploadFile } from './excelToJson';
import { HEAR_ABOUT_US_OPTIONS, MAX_PARTICIPANTS, OTHER_OPT } from '../utils/constants';
import { compareSpecialDates } from '../utils/helpers';

const WrappedFields = (props) => {
  const [participantsCount, setParticipantsCount] = useState(1);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);

  const [isMaxParticipants, setIsMaxParticipants] = useState(false);
  const [hasNegativeParticipants, setHasNegativeParticipants] = useState(false);

  const [showOtherField, setShowOtherField] = useState(false);

  const {
    onSubmit, workshopsData, disableButton, setDisableButton, storeId, onCloseDrawer
  } = props;

  const formatDate = (dateStr) => {
    const date = new Date(Date.parse(dateStr));
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  };

  const checkWorkshopCap = (workshop, participantsToRegisterCount) => {
    const startDate = moment(workshop.startDate);
    const formattedStartDate = startDate.utc().format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
    const month = moment(formattedStartDate).format('MMMM');

    const specialDate = compareSpecialDates(startDate);
    const cap = readUploadFile(storeId, specialDate || month);

    const exceedsCap = workshop.registeredParticipants + participantsToRegisterCount > cap;
    setDisableButton(exceedsCap || participantsToRegisterCount > MAX_PARTICIPANTS || participantsToRegisterCount <= 0);
  };

  const handleChangeWorkshop = (event) => {
    let workshop = event.target.value;
    if (selectedWorkshop !== null && selectedWorkshop.name !== workshop.name) {
      setDisableButton(false);
    }
    setSelectedWorkshop(workshop);
    checkWorkshopCap(workshop, participantsCount);
  };

  const handleOnDropdownChange = (event) => {
    setShowOtherField(event.target.value === OTHER_OPT);
  };

  const handleOnParticipantsChange = (value) => {
    const currentParticipantCount = value;
    const maxPart = currentParticipantCount > MAX_PARTICIPANTS;
    if (maxPart) {
      setIsMaxParticipants(true);
      setHasNegativeParticipants(false);
      return;
    }

    const negativePart = currentParticipantCount <= 0;
    if (negativePart) {
      setHasNegativeParticipants(true);
      setIsMaxParticipants(false);
      return;
    }

    setIsMaxParticipants(false);
    setHasNegativeParticipants(false);
    setParticipantsCount(currentParticipantCount);

    if (selectedWorkshop !== null) {
      checkWorkshopCap(selectedWorkshop, currentParticipantCount);
    }
  };

  const handleClose = () => {
    if (onCloseDrawer) {
      onCloseDrawer();
    }
  };

  return (
    <Form
      onSubmit={(formData) => {
        onSubmit({
          ...formData,
          participants: participantsCount,
        });
      }}
      className="sui-mt-5 sui-grid sui-grid-cols-1 sui-gap-4"
    >
      {/* first name */}
      <FormTextField
        name="firstName"
        label="Parent / Guardian First Name"
        placeholder="Parent / Guardian First Name"
        required
        validate={{
          regex: /^[a-zA-Z\s-]*[a-zA-Z]{2,}[a-zA-Z\s-]*$/,
          regexMessage: 'A valid first name is required',
          typeError: 'error',
        }}
      />
      {/* last name */}
      <FormTextField
        name="lastName"
        label="Parent / Guardian Last Name"
        placeholder="Parent / Guardian Last Name"
        required
        validate={{
          regex: /^[a-zA-Z\s-]*[a-zA-Z]{2,}[a-zA-Z\s-]*$/,
          regexMessage: 'A valid last name is required',
          typeError: 'error',
        }}
      />

      <FormEmail name="email" label="Email" placeholder="johndoe@john.doe" required />

      {/* Replaced FormNumber with QuantityPicker for number of kids attending */}
      <div className="sui-col-span-1 sui-text-left">
        <QuantityPicker
          id="kids-attending"
          label="Number of Kids Attending *"
          min={1}
          max={MAX_PARTICIPANTS}
          value={participantsCount}
          onChange={handleOnParticipantsChange}
        />
      </div>

      {/* select workshop dropdown */}
      <FormDropdown
        name="workshop"
        label="Choose Your Workshop"
        options={workshopsData?.workshops?.map((workshop) => {
          return { value: workshop, label: `${workshop?.name} ${formatDate(workshop?.startDate)}` };
        })}
        onChange={handleChangeWorkshop}
      />
      {/* how did you hear about us? dropdown */}
      <FormDropdown
        name="howDidYouHear"
        label="How did you hear about us?"
        options={HEAR_ABOUT_US_OPTIONS.map((option) => {
          return { value: option, label: option };
        })}
        onChange={handleOnDropdownChange}
        required
      />
      {/* other textfield */}
      {
        showOtherField && (
          <FormTextField
            name="other"
            label="Other"
            placeholder="Specify..."
            required
          />
        )
      }
      {
        disableButton ? (
          <Typography variant="body-sm" color="danger">
            Registration for your selected store is full.
            Please select another store or check back for next month&apos;s workshop.
          </Typography>
        )
          : (
            <Typography variant="body-sm">
              Due to the popularity of the Kids Workshops, we can&apos;t guarantee a kit for every
              participant. We recommend arriving early.
            </Typography>
          )
      }
      {isMaxParticipants && (
        <Typography variant="body-sm" weight="bold">
          * Limit {MAX_PARTICIPANTS} kids per household.
        </Typography>
      )}
      {hasNegativeParticipants && (
        <Typography variant="body-sm" weight="bold">
          * Please enter a valid number of participants (more than 0).
        </Typography>
      )}
      <div className="sui-grid sui-grid-cols-1 sm:sui-grid-cols-2 sui-gap-4 sui-pt-3">
        <div className="sui-order-last sm:sui-order-first">
          <Button
            variant="secondary"
            type="button"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </div>

        <div className="sui-order-first sm:sui-order-last">
          <FormButton
            variant="primary"
            fullWidth
            label="Register"
            disabled={disableButton || isMaxParticipants || hasNegativeParticipants}
          />
        </div>
      </div>

    </Form>
  );
};

WrappedFields.propTypes = {
  onSubmit: func.isRequired,
  disableButton: bool.isRequired,
  setDisableButton: func.isRequired,
  workshopsData: object.isRequired,
  storeId: str.isRequired,
  onCloseDrawer: func,
};

WrappedFields.defaultProps = {
  onCloseDrawer: null,
};

export default WrappedFields;
