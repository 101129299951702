export const Cap = [
  {
    store: '0105',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0106',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0110',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0111',
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0114',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0115',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0116',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0117',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0118',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0119',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0121',
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0122',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0123',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0126',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0127',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0128',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0129',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0130',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0131',
    January: 250,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0132',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0133',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0134',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0136',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0137',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0138',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0139',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0140',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0141',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0143',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0144',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0145',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0146',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0147',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0148',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0149',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0151',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0152',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0153',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0154',
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0155',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0156',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0157',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0159',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0160',
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0161',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0163',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0164',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0165',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0170',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0172',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0174',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0175',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0178',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0179',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0201',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0202',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0203',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0204',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0205',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0206',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0207',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0208',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0209',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0210',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0213',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0218',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0220',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0221',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0222',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0224',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0226',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0232',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0233',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0234',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0238',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0243',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0244',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0245',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0247',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0248',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0249',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0251',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0253',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0254',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0255',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0256',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0257',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0258',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0260',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0261',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0262',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0263',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0264',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0265',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0266',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0268',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0270',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0272',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0273',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0274',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0275',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0276',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0277',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0278',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0279',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0280',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0281',
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0283',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0284',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0285',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0287',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0288',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0289',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0347',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0349',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0352',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0356',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0357',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0358',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0359',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0360',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0362',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0363',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0364',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0365',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0366',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0367',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0368',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0370',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0371',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0373',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0374',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0375',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0376',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0378',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0380',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0381',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0383',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0385',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0386',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0389',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0401',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0402',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0403',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0404',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0405',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0406',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0408',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0410',
    January: 519,
    February: 519,
    March: 519,
    April: 519,
    May: 519,
    June: 519,
    July: 519
  },
  {
    store: '0411',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0413',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0414',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0415',
    January: 384,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: '0416',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0417',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0419',
    January: 384,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: '0420',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0421',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0422',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0423',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0424',
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 480,
    June: 480,
    July: 480
  },
  {
    store: '0428',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0429',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0430',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0431',
    January: 173,
    February: 0,
    March: 0,
    April: 135,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0432',
    January: 634,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: '0441',
    January: 269,
    February: 634,
    March: 634,
    April: 634,
    May: 634,
    June: 634,
    July: 634
  },
  {
    store: '0442',
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0443',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0445',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0446',
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0447',
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0452',
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0453',
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0455',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0456',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0457',
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0458',
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0459',
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0464',
    January: 250,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0467',
    January: 346,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0468',
    January: 404,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0469',
    January: 308,
    February: 404,
    March: 404,
    April: 404,
    May: 442,
    June: 442,
    July: 442
  },
  {
    store: '0470',
    January: 288,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: '0471',
    January: 173,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: '0472',
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0473',
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0474',
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0475',
    January: 269,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0476',
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0477',
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0478',
    January: 212,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0480',
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0481',
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0482',
    January: 384,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0483',
    January: 231,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: '0485',
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0486',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0488',
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0489',
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0501',
    January: 173,
    February: 135,
    March: 135,
    April: 135,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0503',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0504',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0505',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0506',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0507',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0508',
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0509',
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0513',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0514',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0516',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0518',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0519',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0522',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0523',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0524',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0526',
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0527',
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0528',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0529',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0530',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0531',
    January: 231,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0534',
    January: 173,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0537',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0539',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0540',
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0541',
    January: 212,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0542',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0550',
    January: 288,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0551',
    January: 192,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: '0552',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0553',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0554',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0555',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0556',
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0561',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0562',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0563',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0564',
    January: 231,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0565',
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0566',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0568',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0569',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0571',
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0574',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0576',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0577',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0578',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0580',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0581',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0582',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0583',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0584',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0585',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0586',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0587',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0588',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0589',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0597',
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0598',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0601',
    January: 384,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0603',
    January: 192,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: '0606',
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0607',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0608',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0609',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0610',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0611',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0612',
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0614',
    January: 250,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0615',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0616',
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0618',
    January: 442,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0620',
    January: 250,
    February: 442,
    March: 442,
    April: 442,
    May: 442,
    June: 442,
    July: 442
  },
  {
    store: '0625',
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0627',
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0628',
    January: 596,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0629',
    January: 231,
    February: 596,
    March: 596,
    April: 596,
    May: 596,
    June: 596,
    July: 596
  },
  {
    store: '0630',
    January: 327,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0632',
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0633',
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0634',
    January: 288,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0635',
    January: 173,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: '0636',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0637',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0638',
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0639',
    January: 346,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0640',
    January: 212,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0641',
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0642',
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0643',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0644',
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0645',
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0647',
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0648',
    January: 250,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0649',
    January: 173,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0650',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0651',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0652',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0653',
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0654',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0657',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0658',
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0659',
    January: 231,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0660',
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0662',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0663',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0664',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0665',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0667',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0668',
    January: 423,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0669',
    January: 192,
    February: 423,
    March: 423,
    April: 423,
    May: 423,
    June: 423,
    July: 423
  },
  {
    store: '0671',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0673',
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0674',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0679',
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0680',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0683',
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0684',
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0687',
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0701',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0702',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0703',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0704',
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0707',
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0720',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0721',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0722',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0723',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0724',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0725',
    January: 480,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0726',
    January: 173,
    February: 480,
    March: 480,
    April: 480,
    May: 480,
    June: 480,
    July: 480
  },
  {
    store: '0727',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0728',
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0729',
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0730',
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0731',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0732',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0733',
    January: 231,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0734',
    January: 212,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0735',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0736',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0737',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0739',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0740',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0742',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0743',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0744',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0745',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0747',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0748',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0770',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0772',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0773',
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0775',
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0776',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0778',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0779',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0801',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0802',
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0803',
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0804',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0805',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0806',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0808',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0809',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0810',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0812',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0813',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0816',
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0817',
    January: 173,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0818',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0863',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0865',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0866',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0875',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0877',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0880',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0881',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0882',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0883',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0884',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0887',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0888',
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0901',
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0902',
    January: 404,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0903',
    January: 346,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: '0904',
    January: 269,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0906',
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0907',
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0908',
    January: 327,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0909',
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0910',
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0912',
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0914',
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0915',
    January: 212,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0916',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0917',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0918',
    January: 308,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0919',
    January: 173,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: '0920',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0921',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0922',
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0925',
    January: 250,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0926',
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0927',
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0928',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0929',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0930',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0931',
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0932',
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0933',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0934',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0935',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0939',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0940',
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0941',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0942',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0943',
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0944',
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0946',
    January: 250,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: '0947',
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: '0949',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0950',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0951',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0953',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0954',
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0957',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0959',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0961',
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0962',
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0963',
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0965',
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: '0969',
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: '0970',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0974',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0975',
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0976',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0977',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0980',
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: '0981',
    January: 269,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: '0982',
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: '0983',
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0984',
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: '0989',
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1001,
    January: 576,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1002,
    January: 173,
    February: 576,
    March: 576,
    April: 576,
    May: 576,
    June: 576,
    July: 576
  },
  {
    store: 1003,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1005,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1006,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1007,
    January: 365,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1009,
    January: 173,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 1010,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1012,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1013,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1014,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1017,
    January: 212,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1018,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1019,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1020,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1022,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1028,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1030,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1031,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1032,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1034,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1037,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1039,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1040,
    January: 384,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1041,
    January: 173,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 1043,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1044,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1048,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1050,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1052,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1053,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1055,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1059,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1060,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1061,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1062,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1064,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1068,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1069,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1070,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1072,
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1073,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1074,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1076,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1077,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1080,
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1083,
    January: 327,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 1084,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1085,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1086,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1087,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1088,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1089,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1092,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1103,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1104,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1105,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1106,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1108,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1109,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1110,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1112,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1114,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1115,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1116,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1117,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1118,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1119,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1120,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1121,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1122,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1124,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1126,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1127,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1129,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1130,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1171,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1202,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1206,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1207,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1208,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1209,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1211,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1212,
    January: 288,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1213,
    January: 250,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 1214,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1215,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1216,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1217,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1218,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1220,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1221,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1222,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1223,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1225,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1227,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1228,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1229,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1230,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1231,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1233,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1234,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1235,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1236,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1238,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1239,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1241,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1242,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1244,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1245,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1246,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1247,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1248,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1249,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1250,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1251,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1252,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1254,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1255,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 1256,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1257,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1258,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1259,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1261,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1262,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1263,
    January: 212,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1264,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1265,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1266,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1267,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1268,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1269,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1271,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 1272,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1273,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 1274,
    January: 500,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1277,
    January: 212,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 1278,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1281,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1282,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1284,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1285,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1286,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1287,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1289,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1301,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1302,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1303,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1304,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1324,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1326,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1349,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1379,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1380,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1401,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1402,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1403,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1404,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1405,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1406,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1407,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1409,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1410,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1411,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1412,
    January: 404,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1501,
    January: 269,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 1502,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1503,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1504,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1505,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1506,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1507,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1508,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1509,
    January: 596,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1510,
    January: 173,
    February: 596,
    March: 596,
    April: 596,
    May: 596,
    June: 596,
    July: 596
  },
  {
    store: 1511,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1512,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1513,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1514,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1515,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1516,
    January: 250,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1517,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1518,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1519,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1520,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1521,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1522,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1523,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1524,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1525,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1526,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1528,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1529,
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1531,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1532,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1534,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1535,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1537,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1538,
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1540,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1541,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1542,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1544,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1545,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1546,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1547,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1548,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1549,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1550,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1551,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1552,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1601,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1602,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1603,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1604,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1605,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1608,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1610,
    January: 557,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1701,
    January: 346,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 1702,
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 1703,
    January: 173,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1704,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1705,
    January: 500,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1706,
    January: 173,
    February: 519,
    March: 519,
    April: 519,
    May: 519,
    June: 519,
    July: 519
  },
  {
    store: 1707,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1710,
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1747,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1748,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1749,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1750,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1754,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1755,
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1763,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1764,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1769,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1770,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1771,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1772,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1773,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1774,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1775,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1777,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1801,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1802,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1803,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1804,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1805,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1806,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1807,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1808,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1809,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1810,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1832,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1840,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1842,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1843,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1844,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1845,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1846,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1848,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1851,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1853,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1854,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1855,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1856,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1857,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1858,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1859,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1861,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1863,
    January: 212,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1866,
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 173,
    July: 173
  },
  {
    store: 1901,
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1902,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1903,
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1904,
    January: 173,
    February: 346,
    March: 346,
    April: 346,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 1905,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1906,
    January: 231,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1907,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 1908,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1909,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1911,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1912,
    January: 500,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1913,
    January: 173,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 1914,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1915,
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1916,
    January: 212,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 1917,
    January: 346,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1918,
    January: 192,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 1919,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1920,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1921,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1922,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1924,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1926,
    January: 404,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1927,
    January: 423,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 1928,
    January: 173,
    February: 423,
    March: 423,
    April: 423,
    May: 423,
    June: 423,
    July: 423
  },
  {
    store: 1932,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1934,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1935,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1936,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1937,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1938,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1939,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1940,
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 1941,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1942,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1943,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1944,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1948,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1950,
    January: 250,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1952,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1955,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1956,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1957,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1961,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1962,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 1964,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1967,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1969,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1973,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1974,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1975,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1976,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1977,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1978,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1979,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1980,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1981,
    January: 308,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 1982,
    January: 192,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 1983,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 1984,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1986,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 1987,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 1989,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2001,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2002,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2003,
    January: 346,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2009,
    January: 192,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 2010,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2011,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2012,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2014,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2016,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2017,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2018,
    January: 212,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2019,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2021,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2023,
    January: 173,
    February: 154,
    March: 154,
    April: 154,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2024,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2025,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2026,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2030,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2033,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2034,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2036,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2037,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2101,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2103,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2104,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2107,
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2108,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2109,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2111,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2113,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2114,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2115,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2201,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2202,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2203,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2204,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2205,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2207,
    January: 404,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2209,
    January: 192,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 2211,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2213,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2214,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2216,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2217,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2218,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2219,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2220,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2221,
    January: 250,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2301,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2302,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2303,
    January: 346,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2304,
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 2305,
    January: 384,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2306,
    January: 192,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 2307,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2313,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2314,
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2315,
    January: 212,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 2316,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2317,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2318,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2323,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2324,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2389,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2401,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2403,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2404,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2405,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2406,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2407,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2408,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2409,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2410,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2412,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2414,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2501,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2502,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2503,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2504,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2505,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2506,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2507,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2509,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2510,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2511,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2550,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2551,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2552,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2554,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2555,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2557,
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2558,
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 2559,
    January: 250,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2560,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2561,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2562,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2563,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2564,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2565,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2566,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2567,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2571,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2575,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2576,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2577,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2578,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2579,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2580,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2581,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2582,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2583,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2584,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2587,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2589,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2602,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2603,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2605,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2607,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2608,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2609,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2610,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2611,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2612,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2613,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2614,
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2615,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2619,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2623,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2624,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2650,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2651,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2653,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2659,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2662,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2663,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2665,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2667,
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2668,
    January: 250,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 2669,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2670,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2671,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2672,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2673,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2674,
    January: 212,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2676,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2677,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2678,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2679,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2680,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2681,
    January: 231,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2682,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2683,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2684,
    January: 173,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2685,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2686,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2688,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2701,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2702,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2703,
    January: 327,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2704,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2706,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2707,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2708,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2709,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2710,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2711,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2714,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2715,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2716,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2717,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2718,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2720,
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2721,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 2722,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2723,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2724,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2725,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2726,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2727,
    January: 288,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2728,
    January: 173,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 2729,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2731,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2732,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2733,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2734,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2736,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2737,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2738,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2739,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2740,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2741,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2742,
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2743,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2744,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2747,
    January: 288,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2748,
    January: 250,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 2751,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2752,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2754,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2755,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2757,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2758,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2759,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2760,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2761,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2762,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2763,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2764,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2765,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2766,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2767,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2768,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2769,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2770,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2771,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2772,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2773,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2775,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2776,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2779,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2780,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2781,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2782,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2785,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2789,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2801,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2802,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2803,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2804,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2805,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2806,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2807,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2808,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2809,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2810,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2811,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2812,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 2813,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2817,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2818,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2820,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2821,
    January: 250,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 2825,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 2826,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2828,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2829,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2830,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2831,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2832,
    January: 557,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2833,
    January: 192,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 2834,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2840,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2841,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 2842,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2843,
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2844,
    January: 269,
    February: 346,
    March: 346,
    April: 346,
    May: 423,
    June: 423,
    July: 423
  },
  {
    store: 2845,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 2847,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2901,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 2902,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2903,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2905,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2906,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2907,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2909,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2910,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2912,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2913,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2914,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2915,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 2917,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3001,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3002,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3003,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3004,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3006,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3007,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3008,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3009,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3010,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3011,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3012,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3013,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3014,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3015,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3016,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3018,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3019,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3021,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3022,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3024,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3025,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3026,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 3027,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3029,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3032,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3033,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3034,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3036,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3037,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3038,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3042,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3101,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3102,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3103,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3104,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3105,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3106,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3117,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3118,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3119,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3201,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 3202,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3203,
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3204,
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 3206,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3208,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3209,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3210,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3301,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3302,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3303,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3304,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 3305,
    January: 288,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3306,
    January: 192,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 3307,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3308,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3309,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3310,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3311,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3312,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3313,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3314,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3315,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3316,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3318,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3320,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3322,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3324,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3331,
    January: 327,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3401,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 3402,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3403,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3404,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3405,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3406,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3407,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3408,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3409,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3480,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3481,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3482,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3484,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3485,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3486,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3487,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3488,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3489,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3501,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3502,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3503,
    January: 269,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3504,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 3505,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3507,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3508,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3510,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3511,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3514,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3515,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3516,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3517,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3601,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3602,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3603,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3604,
    January: 308,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3605,
    January: 192,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 3606,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3607,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3608,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3609,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3610,
    January: 250,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3615,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3616,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3620,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3625,
    January: 442,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3626,
    January: 192,
    February: 442,
    March: 442,
    April: 442,
    May: 442,
    June: 442,
    July: 442
  },
  {
    store: 3627,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3628,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3629,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3631,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3632,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3633,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3634,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3637,
    January: 384,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3638,
    January: 173,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 3639,
    January: 308,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3640,
    January: 173,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 3641,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3642,
    January: 346,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3644,
    January: 173,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 3645,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3646,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3647,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3648,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3650,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3651,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3652,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 3655,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3661,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3662,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3663,
    January: 346,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3701,
    January: 212,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 3703,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3801,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3803,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3804,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3805,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3806,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3807,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3808,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3809,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3810,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3811,
    January: 365,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3812,
    January: 384,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 3813,
    January: 192,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 3814,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3815,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3816,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3817,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3818,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3819,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3820,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3821,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3822,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3823,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3824,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3825,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3827,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3828,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3830,
    January: 500,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3831,
    January: 288,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 3832,
    January: 192,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 3833,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3835,
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3836,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 3838,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3841,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3842,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3843,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3844,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3846,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3847,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3848,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3852,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3854,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3855,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3856,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3857,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 3858,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3859,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3860,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3861,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3862,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3863,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3864,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3866,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3867,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3868,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3872,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3875,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3877,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3882,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3883,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3885,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3886,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3887,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3888,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3889,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3901,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3902,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3903,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 3904,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3906,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3907,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3908,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3909,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3911,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3913,
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 3914,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 3915,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 3917,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 3918,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 3919,
    January: 1191,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4001,
    January: 269,
    February: 1191,
    March: 1191,
    April: 1191,
    May: 1191,
    June: 1191,
    July: 1191
  },
  {
    store: 4002,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4003,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4004,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4006,
    January: 231,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4007,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4008,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4009,
    January: 519,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4010,
    January: 250,
    February: 519,
    March: 519,
    April: 519,
    May: 519,
    June: 519,
    July: 519
  },
  {
    store: 4013,
    January: 231,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4014,
    January: 308,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4015,
    January: 538,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 4017,
    January: 250,
    February: 538,
    March: 538,
    April: 538,
    May: 538,
    June: 538,
    July: 538
  },
  {
    store: 4018,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4019,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4020,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4023,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4025,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4026,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4028,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4029,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4030,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4031,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4032,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4034,
    January: 308,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4040,
    January: 173,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 4054,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4101,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4102,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4103,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4104,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4105,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4106,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4108,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4109,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4110,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4112,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4113,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4114,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4115,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4116,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4117,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4118,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4119,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4120,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 4121,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4122,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4123,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4124,
    January: 327,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4125,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4126,
    January: 231,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4127,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4128,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4129,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4130,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4131,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4132,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4134,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4135,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4136,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4137,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4138,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4139,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4140,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4141,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4142,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4143,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4145,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4148,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4149,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4150,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4152,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4156,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4158,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4159,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4160,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4162,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4163,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4164,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4166,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4168,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4169,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4171,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4172,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4173,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4176,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4177,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4178,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4180,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4181,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4185,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4186,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4187,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4188,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4213,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4233,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4279,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4280,
    January: 288,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4282,
    January: 212,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 4283,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4284,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4285,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4286,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4287,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4301,
    January: 346,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4401,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 4402,
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 4403,
    January: 500,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4406,
    January: 500,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 4407,
    January: 557,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 4408,
    January: 327,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 4409,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4410,
    January: 231,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4411,
    January: 557,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4412,
    January: 250,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 4413,
    January: 231,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4414,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4415,
    January: 634,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4416,
    January: 672,
    February: 634,
    March: 634,
    April: 634,
    May: 634,
    June: 634,
    July: 634
  },
  {
    store: 4417,
    January: 192,
    February: 672,
    March: 672,
    April: 672,
    May: 672,
    June: 672,
    July: 672
  },
  {
    store: 4418,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4419,
    January: 346,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 4420,
    January: 231,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 4421,
    January: 250,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4422,
    January: 730,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4461,
    January: 173,
    February: 730,
    March: 730,
    April: 730,
    May: 730,
    June: 730,
    July: 730
  },
  {
    store: 4462,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4501,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4502,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4551,
    January: 423,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4601,
    January: 192,
    February: 423,
    March: 423,
    April: 423,
    May: 423,
    June: 423,
    July: 423
  },
  {
    store: 4602,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4603,
    January: 327,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4604,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4605,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4607,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4608,
    January: 192,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4609,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4611,
    January: 173,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4612,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4613,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4614,
    January: 365,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4615,
    January: 173,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 4616,
    January: 404,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4617,
    January: 173,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 4618,
    January: 250,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4619,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4620,
    January: 269,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4621,
    January: 231,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4622,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4624,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4626,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4627,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4628,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4631,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4632,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4633,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4634,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4635,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4637,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4638,
    January: 250,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4639,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4640,
    January: 327,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4641,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4643,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4645,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4647,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4648,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4650,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4653,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4654,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4655,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4656,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4658,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4659,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4660,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4701,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4702,
    January: 231,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4703,
    January: 557,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4704,
    January: 212,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 4705,
    January: 269,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4706,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4707,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4708,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4709,
    January: 250,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4711,
    January: 327,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4712,
    January: 269,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4713,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4714,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4715,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4716,
    January: 384,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4718,
    January: 212,
    February: 384,
    March: 384,
    April: 384,
    May: 384,
    June: 384,
    July: 384
  },
  {
    store: 4719,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4720,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4722,
    January: 730,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4723,
    January: 173,
    February: 730,
    March: 730,
    April: 730,
    May: 730,
    June: 730,
    July: 730
  },
  {
    store: 4724,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4725,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4726,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4727,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4728,
    January: 173,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4732,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4734,
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 173
  },
  {
    store: 4735,
    January: 269,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4737,
    January: 212,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4738,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4739,
    January: 212,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4740,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4741,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4742,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4743,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4745,
    January: 250,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4746,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4747,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4748,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4801,
    January: 192,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4802,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4803,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4805,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4878,
    January: 634,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4902,
    January: 250,
    February: 634,
    March: 634,
    April: 634,
    May: 634,
    June: 634,
    July: 634
  },
  {
    store: 4903,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4905,
    January: 404,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4906,
    January: 288,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 4907,
    January: 519,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 4909,
    January: 250,
    February: 519,
    March: 519,
    April: 519,
    May: 576,
    June: 576,
    July: 576
  },
  {
    store: 4910,
    January: 192,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4911,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4912,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4915,
    January: 173,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4916,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4918,
    January: 269,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 4919,
    January: 288,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4920,
    January: 212,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 4921,
    January: 404,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4923,
    January: 250,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 4924,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4925,
    January: 308,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 4926,
    January: 212,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 4928,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 4929,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 4930,
    January: 288,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4931,
    January: 173,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 4935,
    January: 231,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 4940,
    January: 269,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 4941,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 4942,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6001,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6002,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6003,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6004,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6005,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6037,
    January: 327,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6105,
    January: 288,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6106,
    January: 173,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 6107,
    January: 173,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6113,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6120,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6140,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6150,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6152,
    January: 327,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6153,
    January: 173,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6154,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6155,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6158,
    January: 192,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6159,
    January: 173,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6160,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6161,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6167,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6168,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6169,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6172,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6173,
    January: 212,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6174,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6175,
    January: 173,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6176,
    January: 0,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6178,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6201,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6202,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6203,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6204,
    January: 231,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 6206,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6207,
    January: 442,
    February: 442,
    March: 442,
    April: 442,
    May: 442,
    June: 442,
    July: 442
  },
  {
    store: 6208,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6209,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6210,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6212,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6213,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6214,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6215,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6217,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6218,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6220,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6221,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6223,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6225,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6226,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6228,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6229,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6230,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6233,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6234,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6235,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6236,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6242,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6301,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6302,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6303,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6304,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6305,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6306,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6310,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6311,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6312,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6313,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6314,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6315,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6316,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6319,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6320,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6321,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6322,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6323,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6324,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6325,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6326,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6327,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6328,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6329,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6330,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6331,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6332,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6334,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6335,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6336,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6339,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6340,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6341,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6342,
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6343,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6346,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6348,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6349,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6350,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6351,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6353,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6355,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6356,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6357,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6361,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6363,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6364,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6365,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6367,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6368,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6369,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6371,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6372,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6373,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6374,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6375,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6376,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6377,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6378,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6379,
    January: 288,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 6380,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6381,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6401,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6402,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6403,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6405,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6407,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6408,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6409,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6411,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6412,
    January: 365,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 6415,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6501,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6502,
    January: 288,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 6504,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6505,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6506,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6507,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6509,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6510,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6512,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6513,
    January: 308,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 6514,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6515,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6516,
    January: 192,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 6517,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6520,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6521,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6523,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6525,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6526,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6528,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6529,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6530,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6531,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6532,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6533,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6534,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6537,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6538,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6539,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6540,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6541,
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 173,
    July: 173
  },
  {
    store: 6542,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6543,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6544,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6545,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6546,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6547,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6548,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6549,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6550,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6551,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6552,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6553,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6554,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6555,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6556,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6557,
    January: 596,
    February: 596,
    March: 596,
    April: 596,
    May: 596,
    June: 596,
    July: 596
  },
  {
    store: 6558,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6559,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6560,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6561,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6562,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6563,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6564,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6566,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6567,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6568,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6570,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6571,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6572,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6574,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6575,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6576,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6577,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6578,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6579,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6580,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6581,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6583,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6584,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6585,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6586,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6587,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6588,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6589,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6601,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6603,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6604,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6609,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6610,
    January: 557,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 6611,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6612,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6613,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6614,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6615,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6616,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6617,
    January: 423,
    February: 423,
    March: 423,
    April: 423,
    May: 423,
    June: 423,
    July: 423
  },
  {
    store: 6618,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6619,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6620,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6621,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6623,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6627,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6628,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6629,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6630,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6632,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6634,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6635,
    January: 672,
    February: 672,
    March: 672,
    April: 672,
    May: 672,
    June: 672,
    July: 672
  },
  {
    store: 6636,
    January: 576,
    February: 576,
    March: 576,
    April: 576,
    May: 576,
    June: 576,
    July: 576
  },
  {
    store: 6637,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6638,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6639,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6640,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6643,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6644,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6645,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6646,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6647,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6649,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6650,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6651,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6652,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6654,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6656,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6657,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6660,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6661,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6662,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6663,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6664,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6665,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6667,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6668,
    January: 308,
    February: 308,
    March: 308,
    April: 308,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 6669,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6670,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6672,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6673,
    January: 500,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 6674,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6675,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 6677,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6678,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6679,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6680,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6681,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6682,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6683,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 6684,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6687,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6688,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6689,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6701,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6804,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6806,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6807,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6808,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6809,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6814,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6816,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6817,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6818,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6819,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6820,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6821,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6822,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6827,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6828,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6830,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6831,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6832,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6833,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6834,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6835,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6838,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6839,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6840,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6841,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6842,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6843,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6844,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6845,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6846,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6847,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6848,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6851,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6852,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6853,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6855,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6856,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6857,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6859,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6860,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6861,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6862,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6863,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6864,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6865,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6866,
    January: 212,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6869,
    January: 346,
    February: 346,
    March: 346,
    April: 346,
    May: 346,
    June: 346,
    July: 346
  },
  {
    store: 6871,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6872,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6874,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6875,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6876,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6877,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6878,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6883,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6884,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6887,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6888,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6889,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6890,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6891,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6892,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6893,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6903,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6905,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6911,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6917,
    January: 327,
    February: 327,
    March: 327,
    April: 327,
    May: 327,
    June: 327,
    July: 327
  },
  {
    store: 6918,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6919,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6920,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6921,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6923,
    January: 192,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 6925,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6928,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6930,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6931,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6932,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6934,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6935,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6936,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6940,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6941,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6942,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6943,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6945,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6946,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6947,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6948,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6950,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6951,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6952,
    January: 212,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 6954,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6955,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6956,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6957,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6959,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6960,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6961,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6963,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6964,
    January: 404,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 6965,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6966,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6967,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6968,
    January: 365,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 6971,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6972,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6974,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6975,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6976,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6977,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6978,
    January: 596,
    February: 596,
    March: 596,
    April: 596,
    May: 596,
    June: 596,
    July: 596
  },
  {
    store: 6979,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 6980,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6981,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6984,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6985,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6986,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 6987,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 6988,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 6989,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8201,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8408,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8412,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8413,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8418,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8419,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8426,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8427,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8429,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8430,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8431,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8433,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8437,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8438,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8439,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8440,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8444,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8445,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8446,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8447,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8452,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8453,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 8454,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8456,
    January: 404,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 8460,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8463,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8465,
    January: 404,
    February: 404,
    March: 404,
    April: 404,
    May: 404,
    June: 404,
    July: 404
  },
  {
    store: 8466,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 8469,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8472,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8473,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8475,
    January: 269,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 8488,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8492,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8493,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8517,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 8518,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8519,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8520,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 8521,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8523,
    January: 288,
    February: 288,
    March: 288,
    April: 288,
    May: 288,
    June: 288,
    July: 288
  },
  {
    store: 8524,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8525,
    January: 365,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 8526,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8527,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8528,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8529,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8531,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8537,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8539,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8540,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8545,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8548,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8550,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8551,
    January: 500,
    February: 500,
    March: 500,
    April: 500,
    May: 500,
    June: 500,
    July: 500
  },
  {
    store: 8552,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8557,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8560,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8561,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8562,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8563,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8566,
    January: 423,
    February: 423,
    March: 423,
    April: 423,
    May: 423,
    June: 423,
    July: 423
  },
  {
    store: 8571,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8572,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8577,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8580,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8582,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 8583,
    January: 557,
    February: 557,
    March: 557,
    April: 557,
    May: 557,
    June: 557,
    July: 557
  },
  {
    store: 8584,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8597,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8598,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8622,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8913,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8916,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8918,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8919,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8922,
    January: 212,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 8923,
    January: 365,
    February: 365,
    March: 365,
    April: 365,
    May: 365,
    June: 365,
    July: 365
  },
  {
    store: 8924,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8926,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8929,
    January: 192,
    February: 269,
    March: 269,
    April: 269,
    May: 269,
    June: 269,
    July: 269
  },
  {
    store: 8931,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8938,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8939,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8940,
    January: 231,
    February: 231,
    March: 231,
    April: 231,
    May: 231,
    June: 231,
    July: 231
  },
  {
    store: 8941,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8944,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8949,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 308,
    June: 308,
    July: 308
  },
  {
    store: 8951,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8954,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8955,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8958,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8964,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8966,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8975,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8976,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8979,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8981,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  },
  {
    store: 8984,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8987,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8988,
    January: 192,
    February: 192,
    March: 192,
    April: 192,
    May: 192,
    June: 192,
    July: 192
  },
  {
    store: 8994,
    January: 250,
    February: 250,
    March: 250,
    April: 250,
    May: 250,
    June: 250,
    July: 250
  },
  {
    store: 8995,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8996,
    January: 173,
    February: 173,
    March: 173,
    April: 173,
    May: 173,
    June: 173,
    July: 173
  },
  {
    store: 8998,
    January: 212,
    February: 212,
    March: 212,
    April: 212,
    May: 212,
    June: 212,
    July: 212
  }
];