import React, { useEffect, useMemo } from 'react';

import {
  arrayOf,
  customType,
  alias,
  namedFragment,
  number as numberType,
  shape,
  string as stringType,
  useDataModel,
  extend
} from '@thd-nucleus/data-sources';
import { string, bool, number } from 'prop-types';
import './brand-hero.scss';
import { KidsWorkshopsRegistration } from '@thd-olt-component-react/kids-workshops-registration';
import { BrandHeroRenderer } from './BrandHeroRenderer';

const BrandHero = ({
  componentId,
  componentClass,
  withSideNav = undefined,
  componentPosition = 1,
  cmMmcParam = '' // adobe's Campaign Management Multi-Media Channel parameter to be passed to Citi,
  // so that we can track credit card campaigns through application completions
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hero.ready');
  }, []);

  const { data } = useDataModel('brandedComponent', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  return (
    <BrandHeroRenderer
      componentId={componentId}
      componentClass={componentClass}
      withSideNav={withSideNav}
      componentPosition={componentPosition}
      dataComponent={data?.component}
      cmMmcParam={cmMmcParam}
    />
  );
};

BrandHero.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  withSideNav: bool,
  cmMmcParam: string,
};

BrandHero.displayName = 'BrandHero';

BrandHero.dataModel = extend({
  brandedComponent: alias('component').params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Hero']).isRequired(),
  }).shape({
    Hero: namedFragment({
      inline: true,
      fragmentType: 'Hero',
      fragmentAlias: 'BrandHeroFields'
    }).shape({
      id: stringType(),
      altText: stringType(),
      altTextForLogoBadge: stringType(),
      backgroundColor: stringType(),
      // buttonColor is a required field
      buttonColor: stringType(),
      componentName: stringType(),
      cta: stringType(),
      description: stringType(),
      eyebrow: stringType(),
      link: stringType(),
      logoOrBadge: shape({
        id: stringType(),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
      previewImage: shape({
        id: stringType(),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
      // textColor is a required field
      textColor: stringType(),
      title: stringType(),
      overrideAction: stringType(),

      // TODO: Future work of needing to combine StoryHeroFields and BrandHeroFields into a single data model.
      //
      // StoryHeroFields
      //
      colorTheme: stringType(),
      legalCopy: shape({
        legalText: stringType(),
        drawer: shape({
          headline: stringType(),
          richTextCopyBlock: stringType(),
        })
      }),
    }),
  }),
}, KidsWorkshopsRegistration);

export { BrandHero };
