import moment from 'moment/moment';

export const compareSpecialDates = (startDate) => {
  const blackFriday = moment('2025-11-29', 'YYYY-MM-DD');

  if (startDate.isSame(blackFriday, 'day')) {
    return 'Black Friday';
  }

  return null;
};