import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf,
  params,
  shape,
  string,
  useDataModel,
  number
} from '@thd-nucleus/data-sources';
import {
  Drawer,
  DrawerHeader,
  DrawerBody,
  SkeletonLine,
} from '@one-thd/sui-atomic-components';
import FormContent from './FormContent';
import SuccessContent from './subcomponents/SuccessContent';

const KidsWorkshopsRegistration = ({
  svocId,
  oldStoreId,
  isDrawerOpen,
  toggleDrawer,
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('kids-workshops-registration.ready'); }, []);

  const [drawerPage, setDrawerPage] = useState('form');
  const [registrationData, setRegistrationData] = useState({});

  let storeId = oldStoreId;
  if (storeId && storeId.length === 3) {
    storeId = '0' + storeId;
  }

  const { data: workshopsData, loading } = useDataModel('workshops', {
    variables: { storeId },
  });

  const handleCloseDrawer = () => {
    toggleDrawer(false)();
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setDrawerPage('form');
    }
  }, [isDrawerOpen]);

  return (
    <Drawer open={isDrawerOpen} onClose={handleCloseDrawer} position="right">
      <div
        className="sui-sticky sui-shadow-md"
      >
        <DrawerHeader title="Workshop Registration" onClose={handleCloseDrawer} />
      </div>
      <DrawerBody className="sui-px-2 sui-py-2 sui-overflow-y-auto">
        {loading && (
          <div className="sui-flex sui-flex-col sui-gap-6">
            <SkeletonLine width="80%" />
            <SkeletonLine width="90%" />
            <SkeletonLine width="70%" />
          </div>
        )}
        {!loading
          && (drawerPage === 'success' && registrationData ? (
            <SuccessContent
              registrationData={registrationData}
              isOpen={isDrawerOpen}
              onClose={handleCloseDrawer}
            />
          ) : (
            <FormContent
              setRegistrationData={setRegistrationData}
              workshopsData={workshopsData}
              setDrawerPage={setDrawerPage}
              svocId={svocId}
              storeId={storeId}
              toggleDrawer={toggleDrawer}
            />
          ))}
      </DrawerBody>
    </Drawer>
  );
};

KidsWorkshopsRegistration.displayName = 'KidsWorkshopsRegistration';

KidsWorkshopsRegistration.propTypes = {
  svocId: PropTypes.string,
  oldStoreId: PropTypes.string.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

KidsWorkshopsRegistration.defaultProps = {
  svocId: '123'
};

KidsWorkshopsRegistration.dataModel = {
  workshops: params({ storeId: string().isRequired() }).shape({
    id: string(),
    storeId: string(),
    startDate: string(),
    endDate: string(),
    name: string(),
    description: string(),
    imagePath: string(),
    type: string(),
    registeredParticipants: number(),
  }),
  storeSearch: params({
    storeSearchInput: string(),
    pagesize: string(),
  }).shape({
    stores: arrayOf(
      shape({
        storeId: string(),
        name: string(),
        address: shape({
          street: string(),
          city: string(),
          state: string(),
          postalCode: string(),
          country: string(),
        }),
        phone: string(),
      })
    ),
  }),
};

export { KidsWorkshopsRegistration };
