/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useFloating,
  offset,
  flip,
  size
} from '@floating-ui/react-dom';
import classNames from 'classnames';
import { MenuList } from './MenuList';
import { Paper } from '../private/components/paper/Paper';
import { ClickAwayListener } from '../private/components/clickaway/ClickAwayListener';
import HTMLElementType from '../private/utils/HTMLElementType';
import useForkRef from '../private/hooks/useForkRef';
import useScrollTrigger from '../hooks/useScrollTrigger';
import { Portal } from '../../dist/private/components/portal/Portal';

const canUseDOM = (
  typeof window !== 'undefined'
  && typeof window.document !== 'undefined'
  && typeof window.document.createElement !== 'undefined'
);

const useEnhancedEffect = canUseDOM ? useLayoutEffect : useEffect;

/**
 * `Menu` displays a list of choices that usually appears when the user interacts with a triggering control.
 *
 * Use the `MenuItem` component for each available option.
 *
 * Related components: [MenuItem](#menuitem), [ListItemText](#listitemtext), [DropdownField](#dropdownfield)
 *
 * Usage:
 *
 * ```jsx
 * import { Menu } from '@one-thd/sui-atomic-components';
 * ```
 */
const Menu = React.forwardRef((props, ref) => {
  const {
    autoFocus = true,
    children,
    container,
    disableRefocusOnClose = false,
    MenuListProps = {},
    anchorEl,
    matchAnchorWidth = false,
    onMenuPlaced,
    PaperProps: PaperPropsProp = {},
    placement = 'bottom-start',
    open = false,
    onClose = () => {},
    onScroll,
    style,
    variant = 'selectedMenu',
    ...other
  } = props;

  const autoFocusItem = autoFocus && open;

  const { trigger } = useScrollTrigger({
    monitor: open && (onScroll !== undefined)
  });

  useEffect(() => {
    if (trigger && open && onScroll) {
      onScroll();
    }
  }, [trigger, open]);

  const {
    x: left, y: top, reference, floating, strategy, refs, placement: usedPlacement, update
  } = useFloating({
    placement,
    middleware: [offset(-1), flip(), size(
      {
        apply: ({ reference: { width } }) => {
          const widthStyle = matchAnchorWidth ? { width: `${width}px` } : {};
          Object.assign(refs.floating.current?.style || {}, widthStyle);
        }
      }
    )],
  });

  useEnhancedEffect(() => {
    reference(anchorEl);
  }, [anchorEl]);

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && !disableRefocusOnClose) {
      anchorEl?.focus({ preventScroll: true });
    }

    prevOpen.current = open;
  }, [open, anchorEl, disableRefocusOnClose]);

  useEffect(() => {
    if (onMenuPlaced) {
      onMenuPlaced(usedPlacement);
    }
  }, [onMenuPlaced, usedPlacement]);

  const containerRef = useForkRef(floating, ref);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();

      if (onClose) {
        onClose(event, 'tabKeyDown');
      }
    }
  };

  let activeItemIndex = -1;

  React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return;
    }

    if (!child.props.disabled) {
      if (variant === 'selectedMenu' && child.props.selected) {
        activeItemIndex = index;
      } else if (activeItemIndex === -1) {
        activeItemIndex = index;
      }
    }
  });

  const { variant: paperVariant = 'border', ...rest } = PaperPropsProp;

  const PaperProps = {
    ...rest,
    ...other,
    variant: paperVariant,
    className: classNames('sui-z-100', other.className, rest.className)
  };

  if (!open) return null;

  const Wrapper = (container || container === null) ? Portal : React.Fragment;
  const WrapperProps = (container || container === null) ? { container } : {};

  return (
    <Wrapper {...WrapperProps}>
      <Paper
        ref={containerRef}
        style={{
          ...style,
          position: strategy,
          top: top ?? 'auto',
          left: left ?? 'auto'
        }}
        {...PaperProps}
      >
        <ClickAwayListener onClickAway={onClose}>
          <MenuList
            onKeyDown={handleListKeyDown}
            role="listbox"
            autoFocus={autoFocus && (activeItemIndex === -1)}
            autoFocusItem={autoFocusItem}
            {...MenuListProps}
          >
            {children}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Wrapper>
  );
});

Menu.displayName = 'Menu';

Menu.propTypes = {
  /**
   * An HTML element, or a function that returns one.
   * It's used to set the position of the menu.
   */
  anchorEl: PropTypes.oneOfType([
    HTMLElementType,
    PropTypes.func,
  ]),
  /**
   * If `true` will focus the `[role="menu"]` if no focusable child is found. Disabled
   * children are not focusable. If you set this prop to `false` focus will be placed
   * on the parent modal container.
   * @default true
   */
  autoFocus: PropTypes.bool,
  /**
   * The elements to populate the Menu with.
   */
  children: PropTypes.node.isRequired,
  /**
   * An HTML element, or a function that returns one.
   * If a value is provided it will use the value to set the position of the menu through the Portal.
   */
  container: PropTypes.oneOfType([
    HTMLElementType,
    PropTypes.func,
  ]),
  /**
   * If `true`, the anchorEl will not be refocused on menu close.
   * @default false
   */
  disableRefocusOnClose: PropTypes.bool,
  /**
   * If `true`, the Menu will match the anchor element's width.
   */
  matchAnchorWidth: PropTypes.bool,
  /**
   * Props applied to MenuList.
   * @default {}
   */
  MenuListProps: PropTypes.object,
  /**
   * Event fired when the `Menu` is displayed and placed in the DOM and its final placement has been decided
   */
  onMenuPlaced: PropTypes.func,
  /**
   * @ignore
   */
  onClose: PropTypes.func,
  /**
   * If function is passed, menu will listen page scroll when open.
   */
  onScroll: PropTypes.func,
  /**
   * If true, the `Menu` is displayed. Otherwise null.
   */
  open: PropTypes.bool,
  /**
   * Props applied to the [`Paper`]() component.
   */
  PaperProps: PropTypes.object,
  /**
   * The location where the menu should be located relative to the `anchorElement`.
   * Uses `bottom-start` by default. See all possible options at:
   * [https://floating-ui.com/docs/tutorial#placements](https://floating-ui.com/docs/tutorial#placements)
   */
  placement: PropTypes.string,
  /**
   * @ignore
   * Additional inline style to be applied to `Menu`.
   */
  style: PropTypes.object,
  /**
   * The variant to use. Use `menu` to prevent selected items from impacting the initial focus.
   * @default 'selectedMenu'
   */
  variant: PropTypes.oneOf(['menu', 'selectedMenu'])
};

export { Menu };
